import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';

interface FragaTokenResponse {
  "access_token": string;
  /** restant seconds until token expires */
  "expires_in": number,
  "refresh_expires_in": number,
  "token_type": "Basic" | "Bearer" | "OAuth 2",
  "not-before-policy": number,
  "scope": string
}

@Injectable({
  providedIn: 'root'
})
export class GraphqlAuthService {

  private _http = inject(HttpClient);
  private _dataService = inject(DataService);
  private _tokenSubject = new BehaviorSubject<FragaTokenResponse | null>(null);
  private _isRefreshing = false;


  public getToken(): Observable<string> {
    const token = this._tokenSubject.value;
    if (token && !this._isTokenExpired(token.expires_in)) {
      return new BehaviorSubject(token.access_token);
    } else {
      return this._renewToken();
    }
  }

  private _isTokenExpired(expirationTime: number): boolean {
    const expiresIn = expirationTime * 1000;
    const now = Date.now();
    return now > expiresIn;
  }

  private _renewToken(): Observable<string> {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      const url = `${environment.mkgoURL}/api/v1/token-fraga`;
      return this._dataService.httpOptions(false).pipe(
        switchMap(options => this._http.post<FragaTokenResponse>(url, {}, options)),
        tap(response => {
          response.expires_in += Date.now();
          this._tokenSubject.next(response);
          this._isRefreshing = false;
        }),
        switchMap(() => this._tokenSubject.pipe(map(f => f.access_token)))
      )
    } else {
      return this._tokenSubject.pipe(
        filter(f => !!f),
        map(f => f.access_token)
      );
    }
  }
}
